<template>
  <div class="footerer">
    <div class="footbox">
      <p class="title">产品服务</p>
      <div class="content">
        <div class="item">抖音开放平台服务商</div>
        <div class="item">微信公众号开发</div>
        <div class="item">快手开放平台服务商</div>
        <div class="item">第三方平台</div>
        <div class="item">定制服务</div>
      </div>
    </div>

    <div class="footbox">
      <p class="title">关于我们</p>
      <div class="content">
        <div class="item">客户案例</div>
        <div class="item">产品展示</div>
      </div>
    </div>

    <div class="footbox" style="margin-bottom: 0">
      <p class="title">联系我们</p>
      <div class="content">
        <div class="item">官方客服：18818873575</div>
        <div class="item">商务对接：18818873575</div>
      </div>
    </div>

    <div class="line"></div>

    <p class="footp">
      Copyright 2018-2022 www.yedengtech.com. All Rights Reserved
      北京夜灯教育科技有限公司 版权所有
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footerer {
  width: 100%;
  padding: 20px;
  background-color: rgb(33 36 41);
  .footbox {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .content {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      .item {
        margin-bottom: 10px;
        margin-right: 45px;
        font-size: 12px;
        color: rgb(150 161 202);
      }
    }
  }
}

.title {
  font-size: 13px;
  color: #fff;
  margin-top: 8px;
  margin-bottom: 12px;
}

.line {
  height: 0.5px;
  background: #f8f9fa;
  opacity: 0.1;
  width: 100%;
  margin: 10px 0;
}

.footp{
    font-size: 11px;
    color: rgb(150 161 202);
    line-height: 18px;
    padding-right: 40px;
}
</style>
