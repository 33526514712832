<template>
  <div class="headerer">
    <img class="logo" src="https://oss.yedengtech.com/website-m/logo/logotitle.png" alt="logo" />
    <div class="menu" @click="show=true">
      <svg
        t="1689663948926"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1250"
      >
        <path
          d="M440.32 104.96H135.68a30.72 30.72 0 0 0-30.72 30.72v243.712a30.72 30.72 0 0 0 30.72 30.72H440.32a30.72 30.72 0 0 0 30.72-30.72V135.68a30.72 30.72 0 0 0-30.72-30.72z m-30.7712 61.44v182.2208H166.4V166.4h243.1488z m478.72 447.488H583.68a30.72 30.72 0 0 0-30.72 30.72v243.712a30.72 30.72 0 0 0 30.72 30.72h304.64a30.72 30.72 0 0 0 30.72-30.72v-243.712a30.72 30.72 0 0 0-30.72-30.72z m-30.72 61.44v182.2208h-243.2v-182.272h243.2z m30.72-570.368H583.68a30.72 30.72 0 0 0-30.72 30.72v365.568a30.72 30.72 0 0 0 30.72 30.72h304.64a30.72 30.72 0 0 0 30.72-30.72V135.68a30.72 30.72 0 0 0-30.72-30.72z m-30.72 61.44v304.0768h-243.2V166.4h243.2z m-417.28 325.632H135.68a30.72 30.72 0 0 0-30.72 30.72v365.568a30.72 30.72 0 0 0 30.72 30.72H440.32a30.72 30.72 0 0 0 30.72-30.72v-365.568a30.72 30.72 0 0 0-30.72-30.72z m-30.72 61.44v304.0768H166.4v-304.128h243.1488z"
          fill="#f1a70c"
          p-id="1251"
        ></path>
      </svg>
    </div>

    <van-popup v-model="show" position="right">
      <headmeun></headmeun>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
import headmeun from "./headmeun.vue"
export default {
  components: {
    [Popup.name]: Popup,headmeun
  },
  data(){
    return {
      show:false
    }
  }
};
</script>

<style lang="less" scoped>
.headerer {
  width: 100vw;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 30%;
    object-fit: contain;
  }
  .menu {
    svg {
      width: 32px;
      height: 32px;
    }
  }
}



</style>
